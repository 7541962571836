import React, { useState } from "react";
import "./Projects_content.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Projects_Modal from "../../Common_Component/Projects_Modal/Projects_Modal";

// Sample data for the projects
const projects = [
  {
    title: "Cuplock Scaffolding Project",
    location:
      "Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI Rd, Aundh, Pune, Maharashtra 411027, India",
    imgSrc: "/assets/Images/projects/project-img-1.png",
  },
  {
    title: "Cuplock Scaffolding Project",
    location:
      "Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI Rd, Aundh, Pune, Maharashtra 411027, India",
    imgSrc: "/assets/Images/projects/project-img-1.png",
  },
  {
    title: "Cuplock Scaffolding Project",
    location:
      "Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI Rd, Aundh, Pune, Maharashtra 411027, India",
    imgSrc: "/assets/Images/projects/project-img-1.png",
  },
  {
    title: "Cuplock Scaffolding Project",
    location:
      "Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI Rd, Aundh, Pune, Maharashtra 411027, India",
    imgSrc: "/assets/Images/projects/project-img-1.png",
  },
  {
    title: "Cuplock Scaffolding Project",
    location:
      "Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI Rd, Aundh, Pune, Maharashtra 411027, India",
    imgSrc: "/assets/Images/projects/project-img-1.png",
  },
  {
    title: "Cuplock Scaffolding Project",
    location:
      "Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI Rd, Aundh, Pune, Maharashtra 411027, India",
    imgSrc: "/assets/Images/projects/project-img-1.png",
  },
];

const Projects_content = () => {
  const [modalShow, setModalShow] = React.useState(false);


  return (
    <>
      <section className="About_content_sec Projects_content">
        <div className="container">
          <div className="backgrounddiv Terms_Conditions_content_backgrounddiv">
            <div className="heading-holder-section text-center">
              <h2 className="main-heading">Projects</h2>
              <h3 className="heading">Empowering Progress Through Innovation</h3>
            </div>
            <div className="abt-contdiv">
              <div className="project-card-holder">
                <div className="row">
                  {projects.map((project, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                      <div className="project-card">
                        <div className="img-holder" onClick={() => setModalShow(true)}>
                          <img
                            className="projectimg"
                            src={process.env.PUBLIC_URL + project.imgSrc}
                            alt={project.title}
                          />
                        </div>
                        <div className="text-holder">
                          <h4>{project.title}</h4>
                          <p>
                            <FontAwesomeIcon
                              className="location-icon"
                              icon={faLocationDot}
                            />{" "}
                            {project.location}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Projects_Modal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Projects_content;
