import React from 'react'
import './Privacy_Policy_Banner.css'

const Privacy_Policy_Banner = () => {
  return (
    <>
        <section className='About_banner_sec'>
            <img className='bannerimg' src={process.env.PUBLIC_URL + '/assets/Images/Privacy_Policy/privacy-policy-banner-img.png'} />
            <div className='overlaycolor'></div>
        </section>
    </>
  )
}

export default Privacy_Policy_Banner