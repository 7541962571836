import React from "react";
import Privacy_Policy_content from "./Privacy_Policy_content/Privacy_Policy_content";
import Privacy_Policy_Banner from "./Privacy_Policy_Banner/Privacy_Policy_Banner";

function Privacy_Policy() {
  return (
    <>
      <Privacy_Policy_Banner />
      <Privacy_Policy_content />
    </>
  );
}

export default Privacy_Policy;
