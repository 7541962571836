import React from "react";
import Terms_Conditions_content from "./Terms_Conditions_content/Terms_Conditions_content";
import Terms_Conditions_Banner from "./Terms_Conditions_Banner/Terms_Conditions_Banner";

function Terms_Conditions() {
  return (
    <>
      <Terms_Conditions_Banner />
      <Terms_Conditions_content />
    </>
  );
}

export default Terms_Conditions;
