import React from "react";
import "./Contact_Us_Banner.css";

const Contact_Us_Banner = () => {
  return (
    <>
      <section className="About_banner_sec">
        <img
          className="bannerimg"
          src={
            process.env.PUBLIC_URL +
            "/assets/Images/Contact_Us/contactbg.png"
          }
        />
        <div className="overlaycolor"></div>
        <div className="banner-heading-holder">
          <div className="heading-holder-section text-center ">
            <h2 className="main-heading">We’re Here to Help</h2>
            <h3 className="heading">Contact Us</h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact_Us_Banner;
