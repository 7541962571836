import React from "react";
import "./Type_Ladder_Rent_Content.css";
import { Nav, Tab } from "react-bootstrap";
import Services_slider_section from "../../../Common_Component/Services_slider_section/Services_slider_section";

const Type_Ladder_Rent_Content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder FRP_scaffolding_content common-section">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                              <Services_slider_section />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="tab-content-holder">
                                {/* <h3>WHY CHOOSE FRP SCAFFOLDING?</h3> */}
                                <p>
                                  It is made up of high tensile aluminium
                                  alloys. It is divided into two parts which can
                                  be joined together which makes it easier to
                                  handle. Step with lining provide firm foo hold
                                  grip, complete rubber, shoes and caps top
                                  prevent scratches on the floor and wall
                                </p>
                              </div>

                              <div className="inner-tabs-holder">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="Advantages"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Nav
                                        variant="pills"
                                        className="inner-tabs-container_holder"
                                      >
                                        <Nav.Item>
                                          <Nav.Link eventKey="Advantages">
                                            Advantages
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12">
                                      <Tab.Content className="inner-tab-content">
                                        <Tab.Pane eventKey="Advantages">
                                          <div className="Advantages-list-holder">
                                            <ul>
                                              <li>
                                                It Is Easy To Handle And Move
                                              </li>

                                              <li>
                                                Available In 3 Feet To 12 Feet
                                                As Per Requirement
                                              </li>

                                              <li>
                                                Rubber Will Help To Get The Grip
                                              </li>

                                              <li>
                                                Cap On Top Help To Prevent The
                                                Scratches.
                                              </li>

                                              <li>Light Weight And Durable.</li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Type_Ladder_Rent_Content;
