import React, { useContext, useRef, useState } from "react";
import "./Projects_Modal.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Projects_Modal = (props) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        // size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Studio_big_slider_modal myswiper"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Swiper
            slidesPerView={3}
            spaceBetween={50}
            centeredSlides={true}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              clickable: true,
            }}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            modules={[
              Autoplay,
              // Pagination,
              Navigation,
            ]}
            className="main-slider"

            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              370: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              485: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              575: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              2000: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <div className="slide-heading-holder">
                <h3>Cuplock Scaffolding Project</h3>
              </div>
              <div className="main-slider-img-holder">
                <div className="slider-main">
                  <img
                    className="slider-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
                <div className="text-holder">
                  <p>
                    <FontAwesomeIcon
                      className="location-icon"
                      icon={faLocationDot}
                    />
                    Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI
                    Rd, Aundh, Pune, Maharashtra 411027, India
                  </p>
                </div>
                <div className="overlay-holder"></div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide-heading-holder">
                <h3>Cuplock Scaffolding Project</h3>
              </div>
              <div className="main-slider-img-holder">
                <div className="slider-main">
                  <img
                    className="slider-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
                <div className="text-holder">
                  <p>
                    <FontAwesomeIcon
                      className="location-icon"
                      icon={faLocationDot}
                    />
                    Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI
                    Rd, Aundh, Pune, Maharashtra 411027, India
                  </p>
                </div>
                <div className="overlay-holder"></div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide-heading-holder">
                <h3>Cuplock Scaffolding Project</h3>
              </div>
              <div className="main-slider-img-holder">
                <div className="slider-main">
                  <img
                    className="slider-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
                <div className="text-holder">
                  <p>
                    <FontAwesomeIcon
                      className="location-icon"
                      icon={faLocationDot}
                    />
                    Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI
                    Rd, Aundh, Pune, Maharashtra 411027, India
                  </p>
                </div>
                <div className="overlay-holder"></div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide-heading-holder">
                <h3>Cuplock Scaffolding Project</h3>
              </div>
              <div className="main-slider-img-holder">
                <div className="slider-main">
                  <img
                    className="slider-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
                <div className="text-holder">
                  <p>
                    <FontAwesomeIcon
                      className="location-icon"
                      icon={faLocationDot}
                    />
                    Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI
                    Rd, Aundh, Pune, Maharashtra 411027, India
                  </p>
                </div>
                <div className="overlay-holder"></div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide-heading-holder">
                <h3>Cuplock Scaffolding Project</h3>
              </div>
              <div className="main-slider-img-holder">
                <div className="slider-main">
                  <img
                    className="slider-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
                <div className="text-holder">
                  <p>
                    <FontAwesomeIcon
                      className="location-icon"
                      icon={faLocationDot}
                    />
                    Centriole Building, Plot No 90, Survey #129, 130/1+2, ITI
                    Rd, Aundh, Pune, Maharashtra 411027, India
                  </p>
                </div>
                <div className="overlay-holder"></div>
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="img-list-holder">
            <Swiper
              className="img-list-slider"
              slidesPerView={20}
              spaceBetween={10}

              breakpoints={{
              0: {
                slidesPerView: 8,
                spaceBetween: 5,
              },
              370: {
                slidesPerView: 7,
                spaceBetween: 5,
              },
              485: {
                slidesPerView: 7,
                spaceBetween: 5,
              },
              575: {
                slidesPerView: 7,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 7,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 7,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 8,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 10,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 10,
                spaceBetween: 20,
              },
              2000: {
                slidesPerView: 10,
                spaceBetween: 20,
              },
            }}
            >
              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="img-list-main">
                  <img
                    className="img-list-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/projects/project-img-1.png"
                    }
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Projects_Modal;
