import React from "react";
import "./Privacy_Policy_content.css";

const Privacy_Policy_content = () => {
  return (
    <>
      <section className="About_content_sec Terms_Conditions_content">
        <div className="container">
          <div className="backgrounddiv Terms_Conditions_content_backgrounddiv ">
            <div className="heading-holder-section text-center">
              <h2 className="main-heading">Privacy Policy</h2>
              <h3 className="heading">Privacy Policy</h3>
            </div>

            <div className="abt-contdiv">
              <div className="content-holder">
                <ol>
                  <li>
                    <h3>Introduction </h3>
                    <p>
                      Choudhary Scaffolding Contractor ("we," "our," or "us") is
                      committed to protecting your privacy. This Privacy Policy
                      explains how we collect, use, disclose, and safeguard your
                      information when you visit our website
                      [www.yourwebsite.com] (the "Site"). Please read this
                      Privacy Policy carefully. If you do not agree with the
                      terms of this Privacy Policy, please do not access the
                      Site.
                    </p>
                  </li>

                  <li>
                    <h3> Information We Collect </h3>
                    <p>
                      We may collect information about you in a variety of ways,
                      including:
                    </p>

                    <div className="inner-text-holder">
                      <ul>
                        <li>
                          <p>Personal Data:</p>
                          <p>
                            When you visit our Site, you may voluntarily provide
                            us with personally identifiable information, such as
                            your name, email address, phone number, and company
                            details, when you fill out a contact form, request a
                            quote, or sign up for newsletters.
                          </p>

                          <p>
                            Usage Data: We may automatically collect information
                            about your interactions with our Site, including
                            your IP address, browser type, access times, pages
                            viewed, and the website you visited before our Site.
                          </p>

                          <p>
                            Cookies: We may use cookies, web beacons, and other
                            tracking technologies to collect data about your
                            browsing activity on our Site. You can choose to
                            disable cookies through your browser settings, but
                            this may affect the functionality of the Site.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <h3>How We Use Your Information</h3>
                    <p>
                      We use the information we collect for various purposes,
                      including:
                    </p>

                    <div className="list-text-holder">
                      <ul>
                        <li>
                          <p><span>-</span> To provide and manage the services you request</p>
                        </li>

                        <li>
                          <p>
                          <span>-</span> To respond to your inquiries and provide customer
                            support
                          </p>
                        </li>

                        <li>
                          <p> <span>-</span>  To improve our Site, products, and services</p>
                        </li>

                        <li>
                          <p>
                          <span>-</span> To send you promotional and marketing communications
                            (with your consent)
                          </p>
                        </li>

                        <li>
                          <p><span>-</span> To comply with legal obligations</p>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <h3> Sharing Your Information</h3>
                    <p>
                      We do not sell, rent, or trade your personal information
                      to third parties. We may share your information with:
                    </p>

                    <div className="inner-text-holder inner-text-holder-padding ">
                      <ul>
                        <li>
                          <p>Personal Data:</p>
                          <p>
                            Service Providers: We may share your information
                            with third-party vendors, contractors, or service
                            providers who perform services on our behalf. Legal
                            Requirements: We may disclose your information if
                            required by law or in response to valid requests by
                            public authorities.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <h3> Data Security </h3>
                    <p>
                      We use reasonable administrative, technical, and physical
                      measures to protect your personal information. However, no
                      security system is impenetrable, and we cannot guarantee
                      the absolute security of your data.
                    </p>
                  </li>

                  <li>
                    <h3> Your Rights </h3>
                    <p>
                      Depending on your location, you may have the right to
                      access, correct, update, or delete your personal
                      information. You may also have the right to object to or
                      restrict certain processing activities. To exercise these
                      rights, please contact us at [email address].
                    </p>
                  </li>

                  <li>
                    <h3> Third-Party Websites </h3>
                    <p>
                      Our Site may contain links to third-party websites. We are
                      not responsible for the privacy practices or content of
                      those websites. We encourage you to review the privacy
                      policies of any third-party websites you visit.
                    </p>
                  </li>

                  <li>
                    <h3> hanges to This Privacy Policy </h3>
                    <p>
                      We may update this Privacy Policy from time to time to
                      reflect changes in our practices. We will notify you of
                      any significant changes by posting the new Privacy Policy
                      on our Site and updating the "Last Updated" date.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy_Policy_content;
