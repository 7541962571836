import React from 'react'
import About_banner from './abt-banner/About_banner'
import About_content from './abt-content/About_content'
import About_values from './abt-values/About_values'
import About_team from './abt_team/About_team'
import About_whyschooseus from './abt-whyschoose/About_whyschooseus'

function About_Us() {
  return (
    <>
      <About_banner />
      <About_content />
      <About_values />
      <About_team />
      <About_whyschooseus />
    </>
  )
}

export default About_Us