import React from 'react'
import './Projects_Banner.css'

const Projects_Banner = () => {
  return (
    <>
        <section className='About_banner_sec'>
            <img className='bannerimg' src={process.env.PUBLIC_URL + '/assets/Images/projects/projects-banner-img.png'} />
            <div className='overlaycolor'></div>
        </section>
    </>
  )
}

export default Projects_Banner