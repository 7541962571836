import React from "react";
import "./Baneer-heading-holder.css";

function Baneer_heading_holder({ heading }) {
  return (
    <section className="main-banner-heading-holder">
      <h3>{heading}</h3>
    </section>
  );
}

export default Baneer_heading_holder;
