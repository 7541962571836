import React from "react";
import "./Btn_holder.css";
import { Button } from "react-bootstrap";

function Btn_holder({
  onClick,
  className,
  margin,
  text,
  border,
  background,
  borderRadius,
  fontSize,
  color,
  fontWeight,
  padding,
  icon,
  showHeart,
}) {
  const buttonStyle = {
    background,
    borderRadius,
    border,
    fontSize,
    fontWeight,
    color,
    padding,
    margin,
    className,
  };
  return (
    <>
      <div className="common-btn-holder">
        <Button
          onClick={onClick}
          type="button"
          className={className}
          style={buttonStyle}
        >
          <p>{text}</p>
        </Button>
      </div>
    </>
  );
}

export default Btn_holder;
