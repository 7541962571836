import React, { useEffect, useState } from "react";
import "./Header.css";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function Header() {
  // State to track if navbar is expanded
  const [expanded, setExpanded] = useState(false);

  // State to track if the page is scrolled
  const [isScrolled, setIsScrolled] = useState(false);

   // Function to handle scroll event
   useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className="Header">
        <div className="container">
          <div className={`header_holder ${isScrolled ? "scrollheader" : ""} ${expanded ? "white-bg-holder" : ""}`}>
            <Navbar expand="lg" expanded={expanded} onToggle={setExpanded}>
              <Navbar.Brand as={NavLink} to="/">
                <div className="logo-holder">
                  <img
                    className="headlogo"
                    src={process.env.PUBLIC_URL + "/assets/Images/Header/website-logo.png"}
                    alt="Logo"
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                  <Nav.Link
                    as={NavLink}
                    to="/"
                    activeClassName="active"
                    exact
                    onClick={() => setExpanded(false)} // Close navbar on click
                  >
                    Home
                  </Nav.Link>

                  <Dropdown className="services-dropdown_holder">
                    <Dropdown.Toggle className="services-dropdown" id="dropdown-basic">
                      Services
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/scaffolding-on-rent" onClick={() => setExpanded(false)}>
                        Ms scaffolding on Rent/Sale along with Installation and Dismantling
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/frp-scaffolding" onClick={() => setExpanded(false)}>
                        FRP scaffolding on Rent/Sale
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/type-ladder-Rent" onClick={() => setExpanded(false)}>
                        A Type Ladder Rent/Sale
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/airport-ladder-rent" onClick={() => setExpanded(false)}>
                        Airport Ladder Rent/Sale
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/scaffolding-accessories" onClick={() => setExpanded(false)}>
                        Scaffolding Accessories Rent/Sales
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Nav.Link
                    as={NavLink}
                    to="/about-us"
                    activeClassName="active"
                    onClick={() => setExpanded(false)}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/projects"
                    activeClassName="active"
                    onClick={() => setExpanded(false)}
                  >
                    Projects
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/contact-us"
                    activeClassName="active"
                    onClick={() => setExpanded(false)}
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
