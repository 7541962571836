import React from "react";
import "./Airport_Ladder_Rent_content.css";
import { Nav, Tab } from "react-bootstrap";
import Services_slider_section from "../../../Common_Component/Services_slider_section/Services_slider_section";

const Airport_Ladder_Rent_content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder Airport_Ladder_Rent_content common-section">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                              <Services_slider_section />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="tab-content-holder">
                                <p>
                                  Aluminium Airport Ladders allow your workers
                                  to reach heights safely. Its sturdy and solid
                                  structure provides a better position to work
                                  on walls & ceilings. Caged ladder provides
                                  additional fall protection Railing provides
                                  stability; it helps workers maintain their
                                  balance on steps whilst climbing. Castor
                                  Wheels with brakes prevent rotation and
                                  movement and ensure foundation stability Toe
                                  Boards effectively reduces the chances of
                                  workers, equipment and materials from slipping
                                  or falling from the scaffold platform
                                </p>
                              </div>
                              <hr />
                              <div className="inner-tabs-holder">
                                <div className="inner-tab-content-holder">
                                  <div className="main-table-holder">
                                    <div className="weight-details-holder">
                                      <div className="row">
                                        <div className="col-lg-3 col-md-5">
                                          <div className="inner-tab-heading">
                                            <h3>LADDER HEIGHT:</h3>
                                          </div>
                                        </div>

                                        <div className="  col-lg-9 col-md-7">
                                          <div className="description-holder">
                                            <p>6, 8 , 10 Ft</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="weight-details-holder">
                                      <div className="row">
                                        <div className="col-lg-3 col-md-5">
                                          <div className="inner-tab-heading">
                                            <h3>WORKING HEIGHT:</h3>
                                          </div>
                                        </div>
                                        <div className=" col-lg-9 col-md-7">
                                          <div className="description-holder">
                                            <p>10, 12, 14 Ft</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Airport_Ladder_Rent_content;
