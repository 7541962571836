import React from 'react'
import './About_banner.css'

const About_banner = () => {
  return (
    <>
        <section className='About_banner_sec'>
            <img className='bannerimg' src={process.env.PUBLIC_URL + '/assets/Images/aboutus/banner/abtbanner.png'} />
            <div className='overlaycolor'></div>
        </section>
    </>
  )
}

export default About_banner