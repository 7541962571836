import React from "react";
import "./Your_Inquiries.css";
import { Button, Form } from "react-bootstrap";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";

function Your_Inquiries() {
  return (
    <>
      <section className="Your_Inquiries">
        <div className="container">
          <div className="Your_Inquiries_holder">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
                <div className="img-holder">
                  <img
                    className="Your_Inquiries_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/Home/Your_Inquiries/your-inquiries-img.png"
                    }
                    alt="Your_Inquiries_img"
                  />
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 Your_Inquiries_form">
                <div className="main-form-holder">
                  <div className="Your_Inquiries_form_holder">
                    <div>
                      <div className="heading_holder">
                        <h3 className="heading-text">
                          Your Inquiries Are Welcome!
                        </h3>
                      </div>

                      <div className="form_holder form-section">
                        <Form>
                          <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter First Name"
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Last Name"
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Mail ID</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Enter Mail ID"
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Contact Number</Form.Label>
                                <Form.Control
                                  type="contact-number"
                                  placeholder="Enter Contact Number"
                                />
                              </Form.Group>
                            </div>

                            <div className="col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Message</Form.Label>
                                <Form.Control
                                  className="textarea-holder"
                                  type="text"
                                  as="textarea"
                                  rows={4}
                                  placeholder="Enter Message"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </Form>
                      </div>

                      <div className="btn-holder">
                        <Btn_holder className={"common_btn"} text={"Submit"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Your_Inquiries;
