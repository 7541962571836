import React from "react";
import "./Contact_Us_content.css";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";
import { Form } from "react-bootstrap";
import { faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Contact_Us_content = () => {
  return (
    <>
      <section className="About_content_sec  Contact_Us_content">
        <div className="container">
          <div className="backgrounddiv Contact_Us_content_backgrounddiv ">
            <div className="abt-contdiv">
              <div className="content-holder">
                <div className="heading-holder">
                  <h4 className="heading-text">Get in Touch</h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="info-section-holder">
                      <div className="info-holder">
                        <div className="icon-holder">
                          <FontAwesomeIcon className="icon" icon={faGlobe} />
                        </div>
                        <div className="text-holder">
                          <p>www. choudharyscaffoldingpune.in</p>
                          <p>www.choudharyscaffolding.co.in</p>
                        </div>
                      </div>

                      <div className="info-holder">
                        <div className="icon-holder">
                          <FontAwesomeIcon className="icon" icon={faEnvelope} />
                        </div>
                        <div className="text-holder">
                          <p>choudharyscaffolding@gmail.com</p>
                        </div>
                      </div>

                      <div className="info-holder">
                        <div className="icon-holder">
                          <FontAwesomeIcon className="icon" icon={faPhone} />
                        </div>
                        <div className="text-holder">
                          <p>+91 5436778366</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-card-holder">
                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>Head Office</p>
                        </div>
                        <div className="text-holder">
                          <p>
                            Office No. 104, Morya Business center, JP - 115, G -
                            Block, Sambhaji Nagar, MIDC Chinchwad, Pune -411019
                          </p>
                        </div>
                      </div>

                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>Head Office</p>
                        </div>
                        <div className="text-holder">
                          <p>
                            Office No. 104, Morya Business center, JP - 115, G -
                            Block, Sambhaji Nagar, MIDC Chinchwad, Pune -411019
                          </p>
                        </div>
                      </div>

                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>Head Office</p>
                        </div>
                        <div className="text-holder">
                          <p>
                            Office No. 104, Morya Business center, JP - 115, G -
                            Block, Sambhaji Nagar, MIDC Chinchwad, Pune -411019
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="contact_form_holder">
                      <div>
                        <div className="form_holder form-section">
                          <Form>
                            <div className="row">
                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter First Name"
                                  />
                                </Form.Group>
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Last Name"
                                  />
                                </Form.Group>
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Mail ID</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter Mail ID"
                                  />
                                </Form.Group>
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Contact Number</Form.Label>
                                  <Form.Control
                                    type="contact-number"
                                    placeholder="Enter Contact Number"
                                  />
                                </Form.Group>
                              </div>

                              <div className="col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Message</Form.Label>
                                  <Form.Control
                                    className="textarea-holder"
                                    type="text"
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter Message"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </Form>
                        </div>

                        <div className="btn-holder">
                          <Btn_holder
                            className={"common_btn"}
                            text={"Submit"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="map-holder">
                <div id="google-maps-canvas">
                  <iframe
                    frameBorder="0"
                    src="https://www.google.com/maps/embed/v1/place?q=Office+No.+104,+Morya+Business+center,+JP+-+115,+G+-+Block,+Sambhaji+Nagar,+MIDC+Chinchwad,+Pune+-411019&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact_Us_content;
