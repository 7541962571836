import React from "react";
import Projects_Banner from "./Projects_Banner/Projects_Banner";
import Projects_content from "./Projects_content/Projects_content";

function Projects() {
  return (
    <>
      <Projects_Banner />
      <Projects_content />
    </>
  );
}

export default Projects;
