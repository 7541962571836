import React from "react";
import "./Home.css";
import HomeBanner from "./HomeBanner/HomeBanner";
import OurProfessionalism from "./OurProfessionalism/OurProfessionalism";
import Our_Success from "./Our_Success/Our_Success";
import Our_Services from "./Our_Services/Our_Services";
import Walking_With_Giants from "./Walking_With_Giants/Walking_With_Giants";
import Your_Inquiries from "./Your_Inquiries/Your_Inquiries";
import Customer_Satisfaction_Stories from "./Customer_Satisfaction_Stories/Customer_Satisfaction_Stories";
import About_whyschooseus from "../About_Us/abt-whyschoose/About_whyschooseus";

function Home() {
  return (
    <>
      <HomeBanner />
      <OurProfessionalism />
      <Our_Success />
      <Our_Services />
      <About_whyschooseus />
      <Walking_With_Giants />
      <Your_Inquiries />
      <Customer_Satisfaction_Stories />
    </>
  );
}

export default Home;
