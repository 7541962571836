import React from 'react';
import './Scaffolding_Accessories_content.css';
import { Col, Container, Row } from 'react-bootstrap';

const scaffoldingAccessoriesData = [
    {
        id: '01',
        title: 'COUPLER',
        description: [
            'A pressed double coupler connects two scaffold tubes at right angles. These critical components in the scaffold structure must be load-bearing to resist both slip and distortion.',
            'This design is based on a strong one-piece body with flaps and a t-bolt that can be removed for maintenance or replacement.'
        ],
        imgSrc: '/assets/Images/Services/Scaffolding_Accessories/First.png'
    },
    {
        id: '02',
        title: 'U-JACK',
        description: [
            'Adjustable U jack has a solid steel stem/hollow tube steam of 32/36/38mm diameter.',
            'Which has rut restraint to ensure the stem always has minim engagement into the standard of 150 mm.',
            'The adjustable U jack is capable of accepting twin 100 mm wide bears.'
        ],
        imgSrc: '/assets/Images/Services/Scaffolding_Accessories/sec.png'
    },
    {
        id: '03',
        title: 'BASE PLATE',
        description: [
            'Very efficient metal structure that provides high strength for supporting scaffolding applications.',
            'These base plates are made using the best quality material and ensure resistance against corrosion, long service life, stiffness, and high compressive strength.',
            'Owing to excellent durability, these plates are extensively used in conjunction with adaptors and fork heads and can accept the full loading capability of cup locks when fully braced.'
        ],
        imgSrc: '/assets/Images/Services/Scaffolding_Accessories/third.png'
    }
];

const Scaffolding_Accessories_content = () => {
    return (
        <section className='scaff-text-section common-section'>
            <Container>
                {scaffoldingAccessoriesData.map((accessory, index) => (
                    <div className='grey-bgg-main' key={index}>
                        <Row>
                            <Col xl={1} lg={2} md={2} sm={3} xs={12} className='card-heading-holder'>
                                <div className='main-blur-head'>
                                    <h5 className='blur-head-title'>{accessory.id}.</h5>
                                    <h5 className='blur-head-title'>{accessory.title}</h5>
                                </div>
                            </Col>
                            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
                                <div className='white-bggg-scaff'>
                                    <img src={process.env.PUBLIC_URL + accessory.imgSrc} className="accessories-img" alt={accessory.title} />
                                </div>
                            </Col>
                            <Col xl={7} lg={6} md={7} sm={6} xs={12}>
                                <div className='second-seccc-main ms-xl-4 ms-lg-0 '>
                                    <h5 className='heading-scaff'>{accessory.id}. {accessory.title}</h5>
                                    <ul>
                                        {accessory.description.map((desc, i) => (
                                            <li key={i}>{desc}</li>
                                        ))}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}
            </Container>
        </section>
    );
}

export default Scaffolding_Accessories_content;
