import React from "react";
import "./Terms_Conditions_content.css";

const Terms_Conditions_content = () => {
  return (
    <>
      <section className="About_content_sec Terms_Conditions_content">
        <div className="container">
          <div className="backgrounddiv Terms_Conditions_content_backgrounddiv ">
            <div className="heading-holder-section text-center">
              <h2 className="main-heading">Terms and Conditions</h2>
              <h3 className="heading">Terms and Conditions</h3>
            </div>

            <div className="abt-contdiv">
              <div className="content-holder">
                <ol>
                  <li>
                    <h3>Introduction </h3>
                    <p>
                      Welcome to the Choudhary Scaffolding Contractor website.
                      By accessing or using our website, you agree to comply
                      with and be bound by the following terms and conditions.
                      If you do not agree with any part of these terms, you
                      should not use our website.
                    </p>
                  </li>

                  <li>
                    <h3> Services </h3>
                    <p>
                      Choudhary Scaffolding Contractor provides scaffolding
                      services as outlined on this website. The information
                      provided is for general informational purposes only and
                      does not constitute a binding agreement or offer for
                      services.
                    </p>
                  </li>

                  <li>
                    <h3> Quotations and Contracts </h3>
                    <p>
                      All quotations provided by Choudhary Scaffolding
                      Contractor are subject to change based on site inspections
                      and specific project requirements. A formal contract must
                      be signed by both parties before the commencement of any
                      work.
                    </p>
                  </li>

                  <li>
                    <h3> Payment Terms </h3>
                    <p>
                      Payment terms are outlined in the individual contracts
                      with our clients. Invoices must be paid in full by the due
                      date specified in the contract. Late payments may incur
                      additional fees.
                    </p>
                  </li>

                  <li>
                    <h3> Health and Safety </h3>
                    <p>
                      Choudhary Scaffolding Contractor adheres to strict health
                      and safety regulations. We require all personnel and
                      subcontractors to comply with relevant safety standards
                      while on-site. Clients must also ensure that their site is
                      safe and ready for scaffolding work.
                    </p>
                  </li>

                  <li>
                    <h3> Liability </h3>
                    <p>
                      Choudhary Scaffolding Contractor is not liable for any
                      indirect, incidental, or consequential damages arising
                      from the use of our services or this website. Our
                      liability for direct damages shall be limited to the
                      amount paid by the client for the specific service in
                      question.
                    </p>
                  </li>

                  <li>
                    <h3> Intellectual Property </h3>
                    <p>
                      All content on this website, including text, images,
                      logos, and graphics, is the property of Choudhary
                      Scaffolding Contractor or its content suppliers and is
                      protected by copyright laws. Unauthorized use of any
                      content is prohibited.
                    </p>
                  </li>

                  <li>
                    <h3> Privacy Policy </h3>
                    <p>
                      Your privacy is important to us. Please review our
                      [Privacy Policy](link to your privacy policy) to
                      understand how we collect, use, and protect your personal
                      information.
                    </p>
                  </li>

                  <li>
                    <h3> Changes to Terms</h3>
                    <p>
                      [Your Company Name] reserves the right to modify these
                      terms and conditions at any time. Any changes will be
                      effective immediately upon posting on this website. It is
                      your responsibility to review these terms regularly.
                    </p>
                  </li>

                  <li>
                    <h3>Governing Law </h3>
                    <p>
                      These terms and conditions are governed by and construed
                      in accordance with the laws of [Your Jurisdiction]. Any
                      disputes arising from the use of this website or our
                      services will be subject to the exclusive jurisdiction of
                      the courts in [Your Jurisdiction].
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms_Conditions_content;
