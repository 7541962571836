import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="footer-main-sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="foott-cont">
              <div className="foottitlediv">
                <h1 className="title">Choudhary Scaffolding</h1>
              </div>

              <div className="menus-main-div">
                <div className="flex-menus">
                  <div className="menudiv">
                    <Link to="/" className="menu">
                      Home
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/scaffolding-on-rent" className="menu">
                      Services
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/about-us" className="menu">
                      About Us
                    </Link>
                  </div>

                  <div className="menudiv">
                    <Link to="/contact-us" className="menu">
                      Contact Us
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/terms-and-conditions" className="menu">
                      Terms and Conditions
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/privacy-policy" className="menu">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>

              <div className="addd-sec">
                <div className="row">
                  <div className="col-lg-10 mx-auto">
                    <div className="row">
                      <div className="col-lg-4 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>Head</span>Office
                          </p>
                          <p className="addtitle mt-2">
                            Office No. 104, Morya Business center, JP - 115, G -
                            Block, Sambhaji Nagar, MIDC Chinchwad, Pune -411019
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-4 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>Manufacturing</span>Unit
                          </p>
                          <p className="addtitle mt-2">
                            Pantnagar Colony No.4 Near Dignol Mall, Chikhali,
                            Pune - 4112114
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-4 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>Ware</span>House
                          </p>
                          <p className="addtitle mt-2">
                            Plot No. EL -117,TTC MIDC, Mahpe MIDC area Navi
                            Mumbai
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="copyrightsdiv">
                <p>
                  © 2024 All Rights Reserved Terms Of Use | Privacy Policy
                  Powered by <Link to={"https://profcyma.com/"} target="_black"><span>Profcyma</span></Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
