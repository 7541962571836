import React, { useState } from "react";
import "./OurProfessionalism.css";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";

function OurProfessionalism() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <section
        className="OurProfessionalism"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="container">
          <div className="OurProfessionalism_holder">
            <div className="row">
              <div className="col-xl-5 col-md-6 order-lg-1 order-2">
                <div className="content_holder">
                  <div className="heading_holder">
                    <h3 className="heading-text">About Us</h3>
                  </div>

                  <div className="text_holder">
                    <p>
                      At Choudhary Scaffolding Contractor, professionalism is at
                      the heart of everything we do. We pride ourselves on our
                      expert team, who bring years of experience and specialized
                      knowledge to every project. Our approach combines
                      meticulous planning, adherence to industry standards, and
                      a commitment to safety, ensuring that each scaffolding
                      solution is both reliable and efficient. From timely
                      project execution to clear communication and exceptional
                      customer service, we maintain the highest level of
                      professionalism to deliver results that exceed
                      expectations and build lasting trust with our clients.
                    </p>
                  </div>

                  <div className="btn_holder">
                    <Btn_holder className={"common_btn"} text={"Know More"} />
                  </div>
                </div>
              </div>

              <div className="col-xl-7 col-md-6 order-md-2  order-1">
                <div className="overlay-img-holder">
                  <div className="row overlay-imgs">
                    <div className="col-xl-9 col-md-8 col-6  overlay-text-img-holder">
                      <div className="img-holder bigger-img-holder">
                        <img
                          className="professional_img"
                          src={
                            isHovered
                              ? process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/small-img.png"
                              : process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/big-img.png"
                          }
                          alt="Logo"
                        />
                      </div>

                      <div className="vertical-text-holder">
                        <h4>
                        Our Professionalism
                        </h4>
                      </div>
                    </div>

                    <div className="col-xl-5 col-md-2 col-3 smaller-img-holder">
                      <div className="img-holder ">
                        <img
                          className="professional_img"
                          src={
                            isHovered
                              ? process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/big-img.png"
                              : process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/small-img.png"
                          }
                          alt="Logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-heading-holder">
            <h3>Our Professionalism</h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurProfessionalism;
