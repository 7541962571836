import React from 'react'
import './About_content.css'

const About_content = () => {
    return (
        <>
            <section className='About_content_sec'>
                <div className='container'>
                    <div className='backgrounddiv'>
                        <div className="heading-holder-section ">
                            <h2 className="main-heading">About Us</h2>
                            <h3 className="heading">About Us</h3>
                        </div>



                        <div className='abt-contdiv'>
                            <div className='row'>
                                <div className='col-lg-6 mb-4'>
                                    <div className='sec-1'>
                                        <p className='content'>Since our inception in 1996, Choudhary Scaffolding Contractors is a leading turnkey solution provider for all types of Scaffolding services on rent like MS Cup lock, Ms Trolley, Aluminium trolley and other scaffolding accessories. With over decade we have continuously strived to maintain the highest possible “Safety, Service and Commitment”. This is our principle, which makes us to stand front in queue for efficient, economical, and reliable service-providing organisation. 
                                        </p>
                                        <p  className='content'>                 A new joint venture of “COSTRUCTOR SCAFFOLDING INDIA PVT LTD”. We aim to provide a key supplier in the sale of aluminium scaffolding for all industrial, residential and commercial needs. CONSTRUCTOR SCAFFOLDING INDIA PVT LTD, aluminium trolleys are a generation ahead in term of state of art of technology, light-weight, confined spaces, safe &amp; secure working conditions in extended heights indoor as well as outdoor. These are highly versatile towers provide a strong working platform for verity of heights along with Ideal speed of installation. The “CONSTRUCTOR” enhances the sophisticated interplay of quality and performance in line with building regulation requirements and underpinned by a manufacturing process of meeting the most expected quality standard in aluminium scaffolding.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 mb-4'>
                                    <div className='sec-2'>
                                        <div className='img-div'>
                                            <img className='immage' src={process.env.PUBLIC_URL + '/assets/Images/aboutus/banner/aboutcont.png'} />
                                        </div>
                                        <div className='layerbg'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About_content