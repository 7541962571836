import React from "react";
import "./About_team.css";

const About_team = () => {
  const team = [
    {
      image: "/assets/Images/aboutus/team/per1.png",
      name: "Aditya Jagtap",
      position: "Software Developer",
    },
    {
      image: "/assets/Images/aboutus/team/per2.png",
      name: "Akshay Jagtap",
      position: "Software Developer",
    },
    {
      image: "/assets/Images/aboutus/team/per3.png",
      name: "Sarika Jagtap",
      position: "Software Developer",
    },
    {
      image: "/assets/Images/aboutus/team/per4.png",
      name: "Suraj Jagtap",
      position: "Software Developer",
    },
  ];

  return (
    <>
      <section className="About_team_sec">
        <div className="container">
          <div className="heading-holder-section text-center">
            <h2 className="main-heading">Team</h2>
            <h3 className="heading">Meet Our Dedicated Team Of Experts</h3>
          </div>

          <div className="teamdiv">
            <div className="row">
              {team?.map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-5">
                  <div className="teamcard">
                    <div className="main-img-holder">
                      <div className="img-div">
                        <img
                          className="memberimg"
                          src={process.env.PUBLIC_URL + item.image}
                        />
                      </div>
                    </div>
                    <div className="detailsdiv">
                      <div>
                        <h6 className="name">{item.name}</h6>
                        <p className="position">{item.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About_team;
