import React from "react";
import Services_Banner from "../Services_Banner/Services_Banner";
import Airport_Ladder_Rent_content from "./Airport_Ladder_Rent_content/Airport_Ladder_Rent_content";

function Airport_Ladder_Rent() {
  return (
    <>
      <Services_Banner heading={"Airport Ladder Rent/Sale"} />
      <Airport_Ladder_Rent_content />
    </>
  );
}

export default Airport_Ladder_Rent;
