import React from "react";
import "./Our_Services.css";
import { Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

function Our_Services() {
  const services = [
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-1.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-2.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-3.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-4.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-1.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-2.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-3.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
  ];

  return (
    <>
      <section className="Our_Services">
        <div className="container">
          <div className="Our_Services_holder">
            <div className="heading-holder-section">
              <h2 className="main-heading">Our Services</h2>
              <h3 className="heading">What We Offer</h3>
            </div>

            <div className="Our_Services_card_slider">
              <Swiper
                className="myswiper Our_Services_slider"
                spaceBetween={15}
                slidesPerView={4.2}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  // When the window is >= 320px
                  320: {
                    slidesPerView: 2.2,
                    spaceBetween: 10,
                  },
                  // When the window is >= 640px
                  640: {
                    slidesPerView: 2.2,
                    spaceBetween: 10,
                  },
                  // When the window is >= 768px
                  768: {
                    slidesPerView: 3.2,
                    spaceBetween: 15,
                  },
                  // When the window is >= 1024px
                  1024: {
                    slidesPerView: 4.2,
                    spaceBetween: 15,
                  },
                }}
              >
                {services.map((service, index) => (
                  <SwiperSlide key={index}>
                    <div className="Our_Services_card">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={process.env.PUBLIC_URL + service.imgSrc}
                          alt="Our_Services_img"
                        />
                      </div>

                      <div className="text-btn-holder">
                        <div>
                          <div className="text-holder">
                            <p>{service.description}</p>
                          </div>
                          <div className="view-btn-holder">
                            <Button className="view-btn">View Details</Button>
                          </div>
                        </div>
                      </div>

                      <div className="overlay-holder"></div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Our_Services;
