import React from "react";
import "./Our_Success.css";

function Our_Success() {
  return (
    <>
      <section className="Our_Success">
        <div className="container">
          <div className="Our_Success_holder">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="heading-content-holder">
                  <div className="subheading_holder">
                    <h4 className="subheading">Our Success in Figures</h4>
                  </div>
                  <div className="heading-text_holder">
                    <h2 className="heading-text">Key Performance Highlights</h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 right-border-holder margin-bottom-holder">
                    <div className="counter-holder ">
                      <div className="counter-heading-holder">
                        <h4>Projects Completed</h4>
                      </div>
                      <div className="counter-holder">
                        <h3>
                          41,826 <span>+</span>
                        </h3>
                        <p>Successful Projects</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3  col-sm-6 col-6 right-border-holder margin-bottom-holder">
                    <div className="counter-holder ">
                      <div className="counter-heading-holder">
                        <h4>Years in Business</h4>
                      </div>
                      <div className="counter-holder">
                        <h3>
                          28 <span>+</span>
                        </h3>
                        <p>Years of Experience in Scaffolding</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3  col-sm-6 col-6 right-border-holder margin-bottom-holder">
                    <div className="counter-holder ">
                      <div className="counter-heading-holder">
                        <h4>Clients Served</h4>
                      </div>
                      <div className="counter-holder">
                        <h3>
                          18,726 <span>+</span>
                        </h3>
                        <p>Satisfied Clients</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3  col-sm-6 col-6 no-right-border right-border-holder margin-bottom-holder">
                    <div className="counter-holder">
                      <div className="counter-heading-holder">
                        <h4>Customer Satisfaction</h4>
                      </div>
                      <div className="counter-holder">
                        <h3>
                          4.9/5 <span>+</span>
                        </h3>
                        <p>by Our Clients</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Our_Success;
