import React from 'react'
import './About_values.css'

const About_values = () => {
    return (
        <>
            <section className='About_values_sec'>
                <div className="heading-holder-section text-center">
                    <h2 className="main-heading">Core Values</h2>
                    <h3 className="heading">Core Values</h3>
                </div>

                <div className='imgdiv'>
                    <img className='values-img' src={process.env.PUBLIC_URL + '/assets/Images/aboutus/banner/our_values.png'} />
                </div>
            </section>
        </>
    )
}

export default About_values