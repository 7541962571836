import React from "react";
import Contact_Us_Banner from "./Contact_Us_Banner/Contact_Us_Banner";
import Contact_Us_content from "./Contact_Us_content/Contact_Us_content";

function Contact_Us() {
  return (
    <>
      <Contact_Us_Banner />
      <Contact_Us_content />
    </>
  );
}

export default Contact_Us;
