import React from "react";
import "./FRP_scaffolding_content.css";
import { Nav, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Services_slider_section from "../../../Common_Component/Services_slider_section/Services_slider_section";

const FRP_scaffolding_content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder FRP_scaffolding_content common-section">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                              <Services_slider_section />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="tab-content-holder">
                                <h3>WHY CHOOSE FRP SCAFFOLDING?</h3>
                                <ol className="order-list-holder">
                                  <li>
                                    <h3 className="inner-heading-text">
                                      {" "}
                                      Cost-Effective
                                    </h3>
                                    <p className="text-content">
                                      Reduces labor and material costs while
                                      extending product lifespan.
                                    </p>
                                  </li>

                                  <li>
                                    <h3 className="inner-heading-text">
                                      {" "}
                                      Safety
                                    </h3>
                                    <p className="text-content">
                                      Minimizes risk with its slip-resistant
                                      surface and sturdy structure.
                                    </p>
                                  </li>

                                  <li>
                                    <h3 className="inner-heading-text">
                                      {" "}
                                      Innovation
                                    </h3>
                                    <p className="text-content">
                                      Embrace cutting-edge technology for
                                      improved site efficiency and worker
                                      safety.
                                    </p>
                                  </li>
                                </ol>
                              </div>

                              <div className="inner-tabs-holder">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="Benefits"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Nav
                                        variant="pills"
                                        className="inner-tabs-container_holder"
                                      >
                                        <Nav.Item>
                                          <Nav.Link eventKey="Benefits">
                                            Benefits
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Application">
                                            Application
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12">
                                      <Tab.Content className="inner-tab-content">
                                        <Tab.Pane eventKey="Benefits">
                                          <div className="Advantages-list-holder">
                                            <ul>
                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Corrosion Resistance
                                                </h3>
                                                <p className="text-content">
                                                  It’s Unique And Simpler Type
                                                  Of Aluminium Scaffolding
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Lightweight
                                                </h3>
                                                <p className="text-content">
                                                  A Very Few Material With Light
                                                  Weight Gives Ideal Speed Of
                                                  Installation. Standard Width
                                                  Gives Both Indoor And Outdoor
                                                  Working. Guardrails Frames
                                                  Work As Safeguard. Toe- Board
                                                  Will Work As Fall Arrester Of
                                                  Small Parts Like Tools Form
                                                  Scaffolding
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Lightweight
                                                </h3>
                                                <p className="text-content">
                                                  It’s Unique And Simpler Type
                                                  Of Aluminium Scaffolding A
                                                  Very Few Material With Light
                                                  Weight Gives Ideal Speed Of
                                                  Installation.
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  High Strength-To-Weight Ratio
                                                </h3>
                                                <p className="text-content">
                                                  Standard Width Gives Both
                                                  Indoor And Outdoor Working.
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Low Maintenance
                                                </h3>
                                                <p className="text-content">
                                                  Guardrails Frames Work As
                                                  Safeguard.
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Eco-Friendly
                                                </h3>
                                                <p className="text-content">
                                                  Toe- Board Will Work As Fall
                                                  Arrester Of Small Parts Like
                                                  Tools Form Scaffolding
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Application">
                                          <div className="Advantages-list-holder">
                                            <ul>
                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Construction{" "}
                                                </h3>
                                                <p className="text-content">
                                                  Ideal For Building
                                                  Maintenance, Repairs, And New
                                                  Construction Projects
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Industrial{" "}
                                                </h3>
                                                <p className="text-content">
                                                  Suitable For Oil Rigs,
                                                  Chemical Plants, And Other
                                                  Corrosive Environments
                                                </p>
                                              </li>

                                              <li>
                                                <h3 className="inner-heading-text">
                                                  Infrastructure
                                                </h3>
                                                <p className="text-content">
                                                  Bridges, Highways, And Public
                                                  Works Projects Benefit From
                                                  FRP Scaffolding's Durability.
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FRP_scaffolding_content;
