import React, { useState } from "react";
import "./Customer_Satisfaction_Stories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function Customer_Satisfaction_Stories() {
  // const [swiperInstance, setSwiperInstance] = useState(null);

  // const handleMainSliderClick = (direction) => {
  //   if (swiperInstance) {
  //     if (direction === "prev") {
  //       swiperInstance.slidePrev();
  //     } else if (direction === "next") {
  //       swiperInstance.slideNext();
  //     }
  //   }
  // };

  const stories = [
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-1.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-2.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-3.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-4.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-1.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-2.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
    {
      imgSrc: "/assets/Images/Home/Our_Services/site-img-3.png",
      description:
        "Ms scaffolding on Rent/Sale along with Installation and Dismantling",
    },
  ];
  return (
    <>
      <section className="Customer_Satisfaction_Stories">
        <div className="container">
          <div className="Customer_Satisfaction_Stories_holder">
            <div className="heading_holder">
              <h3 className="heading-text">Customer Satisfaction Stories</h3>
              <h4 className="subheading">What Our Clients Are Saying</h4>
            </div>

            <div className="Stories_slider_holder">
              <Swiper
                className="myswiper Stories_slider"
                spaceBetween={15}
                slidesPerView={3}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  // When the window is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                  },
                  // When the window is >= 640px
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                  },
                  // When the window is >= 768px
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  // When the window is >= 1024px
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },

                  // When the window is >= 1200px
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="Stories_card_holder">
                    <div className="content-holder">
                      <div className="story-text-holder">
                        <p>
                          The team at Choudhary Scaffolding Contractor exceeded
                          our expectations. They were reliable and punctual and
                          provided top-notch scaffolding solutions that met all
                          our needs. Their professionalism and efficiency were
                          impressive.
                        </p>
                      </div>

                      <div className="story-name-text-holder">
                        <p>- Jane D., Project Manager</p>
                      </div>
                    </div>
                    <div className="profile-holder">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/Home/Customer_Satisfaction/profile-img-1.png"
                          }
                          alt="Our_Services_img"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="Stories_card_holder">
                    <div className="content-holder">
                      <div className="story-text-holder">
                        <p>
                          The team at Choudhary Scaffolding Contractor exceeded
                          our expectations. They were reliable and punctual and
                          provided top-notch scaffolding solutions that met all
                          our needs. Their professionalism and efficiency were
                          impressive.
                        </p>
                      </div>

                      <div className="story-name-text-holder">
                        <p>- Jane D., Project Manager</p>
                      </div>
                    </div>
                    <div className="profile-holder">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/Home/Customer_Satisfaction/profile-img-1.png"
                          }
                          alt="Our_Services_img"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="Stories_card_holder">
                    <div className="content-holder">
                      <div className="story-text-holder">
                        <p>
                          The team at Choudhary Scaffolding Contractor exceeded
                          our expectations. They were reliable and punctual and
                          provided top-notch scaffolding solutions that met all
                          our needs. Their professionalism and efficiency were
                          impressive.
                        </p>
                      </div>

                      <div className="story-name-text-holder">
                        <p>- Jane D., Project Manager</p>
                      </div>
                    </div>
                    <div className="profile-holder">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/Home/Customer_Satisfaction/profile-img-1.png"
                          }
                          alt="Our_Services_img"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="Stories_card_holder">
                    <div className="content-holder">
                      <div className="story-text-holder">
                        <p>
                          The team at Choudhary Scaffolding Contractor exceeded
                          our expectations. They were reliable and punctual and
                          provided top-notch scaffolding solutions that met all
                          our needs. Their professionalism and efficiency were
                          impressive.
                        </p>
                      </div>

                      <div className="story-name-text-holder">
                        <p>- Jane D., Project Manager</p>
                      </div>
                    </div>
                    <div className="profile-holder">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/Home/Customer_Satisfaction/profile-img-1.png"
                          }
                          alt="Our_Services_img"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="Stories_card_holder">
                    <div className="content-holder">
                      <div className="story-text-holder">
                        <p>
                          The team at Choudhary Scaffolding Contractor exceeded
                          our expectations. They were reliable and punctual and
                          provided top-notch scaffolding solutions that met all
                          our needs. Their professionalism and efficiency were
                          impressive.
                        </p>
                      </div>

                      <div className="story-name-text-holder">
                        <p>- Jane D., Project Manager</p>
                      </div>
                    </div>
                    <div className="profile-holder">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/Home/Customer_Satisfaction/profile-img-1.png"
                          }
                          alt="Our_Services_img"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>

              {/* <div className="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Customer_Satisfaction_Stories;
