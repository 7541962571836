import React from "react";
import "./Services_Banner.css";
import Baneer_heading_holder from "../../Common_Component/Baneer-heading-holder/Baneer-heading-holder";

const Services_Banner = ({heading}) => {
  return (
    <>
      <section className="About_banner_sec Services_Banner">
        <img
          className="bannerimg"
          src={
            process.env.PUBLIC_URL +
            "/assets/Images/Services/services_banner-img.png"
          }
        />
        <div className="overlaycolor"></div>


        <div className="service-heading-holder">
          <Baneer_heading_holder
            heading={
              heading
            }
          />
        </div>
      </section>
    </>
  );
};

export default Services_Banner;
